var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppTable',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"is-loading":_vm.requestInProgress,"pagination":{
      limit: _vm.swap.limit,
      count: _vm.swap.count,
    }},on:{"change-limit":_vm.changePageLimit,"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"prependFilters",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4"}},[_c('AssetFilter',{on:{"change":_vm.changeAsset},model:{value:(_vm.selectAsset),callback:function ($$v) {_vm.selectAsset=$$v},expression:"selectAsset"}})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"datepicker-placeholder"}},[_vm._v("Status")]),_c('b-form-select',{attrs:{"options":_vm.statusOptions},on:{"change":_vm.changeStatus},model:{value:(_vm.selectStatus),callback:function ($$v) {_vm.selectStatus=$$v},expression:"selectStatus"}})],1)],1),_c('b-col',{staticClass:"mb-1"},[_c('b-form-group',[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c('b-col',{staticClass:"mb-1 mt-auto pb-1",attrs:{"md":"1","xl":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-auto",attrs:{"disabled":_vm.requestInProgress,"type":"button","variant":"primary"},on:{"click":_vm.search}},[_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"18"}})],1)],1)],1)]},proxy:true},{key:"default",fn:function(ref){
    var column = ref.column;
    var row = ref.row;
    var formattedRow = ref.formattedRow;
return [(column.field === 'user')?_c('span',{staticClass:"flex-center-align "},[_c('b-button',{staticClass:"text-left",attrs:{"variant":"link","target":"_blank"},on:{"click":row.user.action}},[_vm._v(" "+_vm._s(row.user.name)+" ")])],1):(column.field === 'userWallet')?_c('span',{staticClass:"flex-center-align"},[_c('b-button',{staticClass:"p-1 font-16 text-left",attrs:{"variant":"link"},on:{"click":function($event){return _vm.doCopy(row.userWallet.raw)}}},[_vm._v(" "+_vm._s(row.userWallet.text)+" ")])],1):(column.field === 'status')?_c('span',{staticClass:"flex-center-align"},[_c('b-badge',{staticClass:"text-white",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(row.status || '')+" ")])],1):_c('span',{staticClass:"flex-center-align"},[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }