export default [
  {
    label: 'User',
    field: 'user',
  },
  {
    label: 'Time',
    field: 'createdAt',
  },
  {
    label: 'User wallet',
    field: 'userWallet',
  },
  {
    label: 'Pay',
    field: 'pay',
  },
  {
    label: 'Get',
    field: 'get',
  },
  {
    label: 'Rate',
    field: 'rate',
  },
  {
    label: 'Service Fee',
    field: 'serviceFee',
  },
  {
    label: 'Network Fee',
    field: 'networkFee',
  },
  {
    label: 'Status',
    field: 'status',
  },
]
